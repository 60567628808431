import axios from "axios";
import { authHeader } from "../helpers/authservice/auth-header";

export const API_BASE_URL = "https://pp.avocableu.com/api";

// Setup an interceptor to inject the Authorization header before each request
axios.interceptors.request.use(
    (config) => {
        const auth = authHeader(); // Get the current auth header
        if (auth && auth.Authorization) {
            config.headers.Authorization = auth.Authorization; // Set the Authorization header
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// login
export async function login(email, password) {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, {
            email,
            password,
        });
        return response.data;
    } catch (error) {
        return {
            data: error.response.data,
            status: "errors",
        };
    }
}

export async function autoSuggest(at, address, limit = 5) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/here/auto-suggest`,
            { at, address, limit }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during auto suggest");
    }
}

export async function getPropertyData(id) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-property-data`,
            { id }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during auto suggest");
    }
}

export async function getrouting(origin, range, type) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-routing/${type}`,
            {
                origin: origin,
                range: range,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching routing data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during auto suggest");
    }
}

export async function getplans() {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/subscription/plans`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching routing data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during auto suggest");
    }
}

export async function getUserSearches() {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/user/search`);
        return response.data;
    } catch (error) {
        console.error("Error fetching routing data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during auto suggest");
    }
}

export async function getDesirabilityData(id) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-desirability-data`,
            { id }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching routing data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during auto suggest");
    }
}

export async function getAccessibilityData(id) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-accessibility-data`,
            { id }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching accessibility data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting accessibility data");
    }
}

export async function getAttomSchoolData(id, radius) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-attom-school-data`,
            { id, radius }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching school data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting school data");
    }
}

export async function getHomeFeaturesData(id) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-home-features-data`,
            { id }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching home features data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting home features data");
    }
}

export async function getAffordabilityData(id) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/get-affordability-data`,
            { id }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching affordability data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting affordability data");
    }
}

export async function getLookupId(id) {
    try {
        const response = await axios.post(`${API_BASE_URL}/v1/get-lookup-id`, {
            id,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching lookup data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting lookup data");
    }
}

export async function requestToken(email) {
    try {
        const response = await axios.post(`${API_BASE_URL}/request-token`, {
            email,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching lookup data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting lookup data");
    }
}

export async function sendToken(email, token) {
    try {
        const response = await axios.post(`${API_BASE_URL}/confirm-token`, {
            email,
            token,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching lookup data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting lookup data");
    }
}

export async function updatedesirabilityScore(id) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/update-desirability-score`,
            {
                id,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching lookup data:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during getting lookup data");
    }
}

export async function payWithStripe(
    amount,
    payment_method_id,
    plan_id,
    coupon = "",
    couponDiscount = 0,
    billingCycle = 1
) {
    try {
        const response = await axios.post(`${API_BASE_URL}/v1/payment/pay`, {
            amount,
            payment_method_id,
            plan_id,
            coupon,
            couponDiscount,
            billingCycle,
        });
        return response.data;
    } catch (error) {
        console.error("Payment Failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during your payment");
    }
}

export async function applyCoupon(couponCode, planId, duration) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/payment/check-coupon`,
            {
                coupon_code: couponCode,
                plan_id: planId,
                duration: duration,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Check coupon failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during checking coupon");
    }
}

export async function updateUserProfile(personalInfo) {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/v1/user/update-profile`,
            personalInfo
        );
        return response.data;
    } catch (error) {
        console.error("Profile update failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during profile update");
    }
}

export async function fetchUserUsageData() {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/reports/usage`);
        return response.data;
    } catch (error) {
        console.error("fetching user data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching user data");
    }
}

export async function fetchUserPaymentsData() {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/reports/payments`);
        return response.data;
    } catch (error) {
        console.error("fetching user data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching user data");
    }
}

export async function fetchEmployabilityPolygonData(params) {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/v1/maps/employability`,
            {
                params: params,
            }
        );
        return response.data;
    } catch (error) {
        console.error("fetching employability data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching employability data");
    }
}

export async function fetchPollutionPolygonData(params) {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/maps/pollution`, {
            params: params,
        });
        return response.data;
    } catch (error) {
        console.error("fetching pollution data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching pollution data");
    }
}

export async function fetchTreeCanopyPolygonData(params) {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/maps/canopy`, {
            params: params,
        });
        return response.data;
    } catch (error) {
        console.error("fetching tree canopy data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching tree canopy data");
    }
}

export async function fetchRvData() {
    try {
        const response = await axios.get(`${API_BASE_URL}/v1/maps/rv`);
        return response.data;
    } catch (error) {
        console.error("fetching rv data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching rv data");
    }
}

export async function checkUserCredit() {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/v1/subscription/check-user-credit`
        );
        return response.data;
    } catch (error) {
        console.error("user credit data failed:", error);
        throw error.response
            ? error.response.data
            : new Error("An error occurred during fetching user credit");
    }
}

export default axios;
