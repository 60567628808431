import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== "production",
});

// Subscribe to store mutations
store.subscribe((mutation, state) => {
    if (mutation.type === "layout/setSelectedPlan") {
        localStorage.setItem(
            "selectedPlan",
            JSON.stringify(state.layout.selectedPlan)
        );
    }
});

export default store;
