import Swal from "sweetalert2";

export function saError(title = "", errorMessage) {
    return Swal.fire({
        title: title,
        text: errorMessage,
        icon: "error",
        customClass: {
            confirmButton: "btn btn-primary w-xs mt-2",
        },
        buttonsStyling: false,
        // footer: '<BLink href="">Why do I have this issue?</BLink>',
        showCloseButton: true,
    });
}

export function saSuccess(title = "", successMessage) {
    return Swal.fire({
        title: title,
        text: successMessage,
        icon: "success",
        customClass: {
            confirmButton: "btn btn-primary w-xs mt-2",
        },
        buttonsStyling: false,
        // footer: '<BLink href="">Why do I have this issue?</BLink>',
        showCloseButton: true,
    });
}
