import store from "@/state/store";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                    next();
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/account/register.vue"),
        meta: {
            title: "Register",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/",
        name: "default",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        // component: () => import("../views/dashboard/ecommerce/index.vue"),
        component: () => import("../views/maps/leaflet/index"),
    },
    {
        path: "/auth/verify",
        name: "verification",
        meta: {
            title: "verification",
            beforeResolve: (routeTo, routeFrom, next) => {
                const email = localStorage.getItem("email");
                if (email) {
                    next();
                } else {
                    next("/");
                }
            },
        },
        component: () => import("../views/auth/verify"),
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("../views/auth/logout/basic"),
    },

    {
        path: "/ecommerce/orders",
        name: "orders",
        meta: { title: "Orders", authRequired: true },
        component: () => import("../views/apps/ecommerce/orders"),
    },
    {
        path: "/ecommerce/order-details",
        name: "order-details",
        meta: { title: "Order Details", authRequired: true },
        component: () => import("../views/apps/ecommerce/order-details"),
    },

    {
        path: "/ecommerce/shopping-cart",
        name: "shopping cart",
        meta: { title: "Shopping Cart", authRequired: true },
        component: () => import("../views/apps/ecommerce/shopping-cart"),
    },
    {
        path: "/ecommerce/checkout",
        name: "checkout",
        meta: { title: "Check Out", authRequired: true },
        component: () => import("../views/apps/ecommerce/checkout"),
    },

    {
        path: "/pages/coming-soon",
        name: "coming-soon",
        meta: { title: "Comming Soon", authRequired: true },
        component: () => import("../views/pages/coming-soon"),
    },
    {
        path: "/pages/pricing",
        name: "pricing",
        meta: { title: "Pricing", authRequired: true },
        component: () => import("../views/pages/pricing"),
    },
    {
        path: "/pages/privacy-policy",
        name: "privacy-policy",
        meta: { title: "Privacy Policy", authRequired: true },
        component: () => import("../views/pages/privacy-policy"),
    },
    {
        path: "/pages/term-conditions",
        name: "term-conditions",
        meta: { title: "Term Conditions", authRequired: true },
        component: () => import("../views/pages/term-conditions"),
    },

    {
        path: "/auth/lockscreen-basic",
        name: "lock-screen-basic",
        meta: {
            title: "Lock Screen",
            authRequired: true,
        },
        component: () => import("../views/auth/lockscreen/basic"),
    },
    {
        path: "/auth/lockscreen-cover",
        name: "lock-screen-cover",
        meta: {
            title: "Lock Screen",
            authRequired: true,
        },
        component: () => import("../views/auth/lockscreen/cover"),
    },
    {
        path: "/auth/404",
        name: "404",
        meta: {
            title: "Error 404",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/404"),
    },
    {
        path: "/auth/500",
        name: "500",
        meta: {
            title: "Error 500",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/500"),
    },
    {
        path: "/auth/404-basic",
        name: "404-basic",
        meta: {
            title: "Error 404",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/404-basic"),
    },
    {
        path: "/auth/404-cover",
        name: "404-cover",
        meta: {
            title: "Error 404",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/404-cover"),
    },
    {
        path: "/auth/ofline",
        name: "ofline",
        meta: {
            title: "Offline",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/ofline"),
    },
    {
        path: "/auth/logout-basic",
        name: "logout-basic",
        meta: {
            title: "Logout",
            authRequired: true,
        },
        component: () => import("../views/auth/logout/basic"),
    },
    {
        path: "/auth/logout-cover",
        name: "logout-cover",
        meta: {
            title: "Logout",
            authRequired: true,
        },
        component: () => import("../views/auth/logout/cover"),
    },
    {
        path: "/dashboard/usage",
        name: "dashboard-usage",
        meta: {
            title: "Your Usage Report",
            authRequired: true,
        },
        component: () => import("../views/dashboard/crm/index"),
    },
    {
        path: "/maps/heatmaps",
        name: "Heat Maps",
        meta: { title: "Heat Maps", authRequired: true },
        component: () => import("../views/maps/leaflet/heatmap"),
    },
];
